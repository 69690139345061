import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import BlogContainer from "../components/Blog/BlogContainer"

export const getBlog = graphql`
  query BlogQuery {
    blog: allSanityPost {
      posts: nodes {
        featured
        ...Post
      }
    }
  }
`
const Blog = ({ data }) => {
  return (
    <>
      <SEO title="Blog" />
      <BlogContainer content={data.blog.posts} />
    </>
  )
}

export default Blog
