import React from "react"

import BlogPreviewList from "./BlogPreviewList"
import { BlogWrapper } from "./Blog.styles"
import FeaturedBlogPosts from "./FeaturedBlogPosts"

const BlogDisplay = ({ posts, featured }) => {
  return (
    <BlogWrapper>
      <FeaturedBlogPosts posts={featured} />
      <BlogPreviewList hideExecerpt={true} posts={posts} />
    </BlogWrapper>
  )
}

export default BlogDisplay
