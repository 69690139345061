import React, { useRef, useEffect, useState } from "react"
import BlogPreviewblock from "./BlogPreviewblock"
import { Featured } from "./FeaturedBlogPosts.styles"
import useSlider, { Slide } from "../Slider"

const FeaturedBlogPosts = ({ posts }) => {
  const [, setHeight] = useState(0)
  const slideRef = useRef(null)

  const { activeIndex, visibleSlides, Dots } = useSlider({ slides: posts })

  useEffect(() => {
    if (!slideRef.current) return
    setHeight(slideRef.current.offsetHeight)
  }, [activeIndex])

  return (
    <Featured>
      <div className="inner">
        {visibleSlides.map((blogItem, index) => (
          <Slide
            ref={index === activeIndex ? slideRef : null}
            active={index === activeIndex}
            key={blogItem._id}
          >
            <BlogPreviewblock featured {...blogItem} />
          </Slide>
        ))}
        <Dots />
      </div>
    </Featured>
  )
}

export default FeaturedBlogPosts
