import React from 'react';
import { Nav } from './Slider.styles';

const SliderNavigation = ({ next, prev}) => {
	return (
		<Nav>
			<button onClick={prev}>back</button>
			<button onClick={next}>next</button>
		</Nav>
	);
};

export default SliderNavigation;