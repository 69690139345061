import styled from "@emotion/styled"
import { wrapper } from "../../styles/shared.styles"
import { colors, fixedValues } from "../../styles/variables.styles"
import { mq } from "../../styles/helpers.styles"

export const Featured = styled.div`
  position: relative;
  height: 70vh;
  overflow: hidden;
  margin-bottom: 5vh;
  background: ${colors.primary.blue};
  padding-top: ${`calc(${fixedValues.headerHeightMobile} + 5vh)`};
  ${mq.tablet} {
    height: 60vh;
    padding-top: ${`calc(${fixedValues.headerHeight} + 5vh)`};
  }
  ${mq.desktop} {
    height: 70vh;
    padding-top: ${`calc(${fixedValues.headerHeight} + 5vh)`};
  }
  ${mq.desktop__l} {
    padding-top: ${`calc(${fixedValues.largeHeaderHeight} + 5vh)`};
  }
  .inner {
    ${wrapper};
    position: relative;
    height: 100%;
  }
`
