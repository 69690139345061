import React, { useEffect } from "react"

import { useTheme } from "../../hooks/useTheme"

import BlogDisplay from "./BlogDisplay"

const BlogContainer = ({ content }) => {
  const featured = content.filter(({ featured }) => featured)
  const rest = content.filter(({ featured }) => !featured)

  const { setTheme } = useTheme()
  useEffect(() => {
    setTheme("blue")
  }, [setTheme])

  return <BlogDisplay posts={rest} featured={featured} />
}

export default BlogContainer
