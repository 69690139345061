/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import BlogPreviewblock from "./BlogPreviewblock"
import { Grid, wrapper } from '../../styles/shared.styles';

const BlogPreviewList = ({posts, hideExecerpt}) => {
	return (
		<Grid css={css`
			${wrapper}`
			}>
			{posts.map(blogItem => (
        <BlogPreviewblock hideExecerpt={hideExecerpt} key={blogItem._id} {...blogItem} />
      ))}
		</Grid>
	);
};

export default BlogPreviewList;