import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { mq } from "../../styles/helpers.styles";
import { spacing } from "../../styles/variables.styles";


const visible = css`
 visibility: visible;
 opacity: 1;
`
const hidden = css`
 visibility: hidden;
 opacity: 0;
`

export const Slide = styled.div`
	${({active}) => active ? visible : hidden}
	transition: 500ms ease-in-out;
	position: absolute;
	left: ${spacing.mobile};
	right: ${spacing.mobile};
  margin: 0 auto;

  ${mq.desktop} {
		left: ${spacing.tablet};
		right: ${spacing.tablet};
  }

  ${mq.desktop} {
		left: ${spacing.desktop};
		right: ${spacing.desktop};
  }
	
  ${mq.desktop__l} {
		left: ${spacing.dekstop__l};
		right: ${spacing.dekstop__l};
  }

`

const nav_style = css`
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translateX(-50%);
`

export const Nav = styled.nav`
	${nav_style}
`

export const Dots = styled.div`
	${nav_style}
	display: flex;
	width: 100%;
	justify-content: center;
`

export const Dot = styled.div`
	transition: 1s linear;
	opacity: ${({active}) => active ? 1 : 0.5};
	font-size: 7em;
	margin: 0 0.1em;
	cursor: pointer;
`