import React, { useState, useEffect } from "react"

import SliderNavigation from "./SliderNavigation"
import SliderDots from "./SliderDots"

const useSlider = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const initialState = slides.length > 1 ? [slides[0], slides[1]] : [slides[0]]
  const [visibleSlides, updateVisibleSlides] = useState(initialState)

  useEffect(() => {
    updateVisibleSlides(initialState)
  }, [initialState])

  function goTo(index) {
    setActiveIndex(index)
  }

  function next() {
    if (slides.length > visibleSlides.length) {
      updateVisibleSlides(prevSlides => {
        return [...prevSlides, slides[visibleSlides.length]]
      })
    }
    setActiveIndex(prevIndex => (prevIndex + 1) % visibleSlides.length)
  }

  function prev() {
    setActiveIndex(
      prevIndex => (prevIndex - 1 + visibleSlides.length) % visibleSlides.length
    )
  }

  return {
    visibleSlides,
    activeIndex,
    Navigation: () => <SliderNavigation next={next} prev={prev} />,
    Dots: () => (
      <SliderDots
        activeIndex={activeIndex}
        goTo={goTo}
        slides={visibleSlides}
      />
    ),
  }
}

export default useSlider
