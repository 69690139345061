import React from "react"
import { Link } from "gatsby"
import { format } from "date-fns"

import PortableText from "../PortableText"
import {
  Preview,
  BlogPreviewTitle,
  BlogPreviewExcerpt,
  BlogPreviewDate,
  BlogPreviewCategory,
  PreviewMeta,
} from "./Blog.styles"

const BlogPreviewblock = ({
  title,
  slug,
  _rawExcerpt,
  publishedAt,
  hideExecerpt,
  featured,
  category,
}) => {
  return (
    <Preview featured={featured}>
      <Link to={`/blog/${slug.current}`}>
        <PreviewMeta featured={featured}>
          {publishedAt && (
            <BlogPreviewDate>
              {format(new Date(publishedAt), "MM / dd / yyyy")}
            </BlogPreviewDate>
          )}
          {category && (
            <BlogPreviewCategory>{category.title}</BlogPreviewCategory>
          )}
        </PreviewMeta>
        <BlogPreviewTitle featured={featured}>{title}</BlogPreviewTitle>
        {_rawExcerpt && !hideExecerpt && (
          <BlogPreviewExcerpt>
            <PortableText blocks={_rawExcerpt} />
          </BlogPreviewExcerpt>
        )}
      </Link>
    </Preview>
  )
}

export default BlogPreviewblock
